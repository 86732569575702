var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'product-content-tabs-container',
		{ sidebar: _vm.viewMode == 'sidebar' } ]},[(_vm.purchased && _vm.viewMode != 'sidebar')?_c('div',{class:[
			'd-flex mb-4',
			{
				'flex-column': _vm.isMobile,
				'justify-content-between align-items-center': !_vm.isMobile,
			} ]}):_vm._e(),(
			_vm.filteredProductVariantItems && _vm.filteredProductVariantItems.length > 1
		)?_c('ul',{staticClass:"product-courses-tabs inline-list hide-tab-mobile"},_vm._l((_vm.filteredProductVariantItems),function(course){return _c('li',{key:course.id,class:[
				'course-tab',
				{ active: _vm.activeTab == course.id, sidebar: _vm.viewMode == 'sidebar' } ],attrs:{"id":course.id},on:{"click":_vm.changeTab}},[_vm._v(" "+_vm._s(course.title)+" ")])}),0):_vm._e(),_vm._l((_vm.filteredProductVariantItems),function(courseTab){return _c('div',{key:courseTab.id,class:[
			'course-tab-body hide-tab-mobile',
			{
				'first-tab': _vm.activeTab == _vm.filteredProductVariantItems[0].id,
				'last-tab':
					_vm.activeTab ==
					_vm.filteredProductVariantItems[_vm.filteredProductVariantItems.length - 1]
						.id,
				'tab-hidden': _vm.activeTab != courseTab.id,
				sidebar: _vm.viewMode == 'sidebar',
			} ]},[(_vm.tabContent && _vm.activeTab == courseTab.id)?_c('ProductContentAccordianList',{attrs:{"tabData":_vm.tabData,"viewMode":_vm.viewMode,"purchased":_vm.purchased,"productVariantId":_vm.productVariantData.id}}):_c('div',{staticClass:"skeleton"},[_c('div',{staticClass:"skeleton-body ms-0 ps-0 mt-2"},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"20%"}}),_c('b-skeleton',{staticClass:"mt-3",attrs:{"type":"input","height":"60px","width":"100%"}}),_c('b-skeleton',{staticClass:"mt-3",attrs:{"type":"input","height":"60px","width":"100%"}}),_c('b-skeleton',{staticClass:"mt-3",attrs:{"type":"input","height":"60px","width":"100%"}})],1)])],1)}),(_vm.isMobile)?_c('div',{staticClass:"accordion mobile-only",attrs:{"id":"product-content-accordian"}},_vm._l((_vm.filteredProductVariantItems),function(course){return _c('div',{key:course.id,staticClass:"accordion-item content-mobile-accordian"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":'heading' + course.id}},[_c('button',{staticClass:"accordion-button collapsed",attrs:{"id":course.id,"type":"button","data-bs-toggle":"collapse","data-bs-target":'#collapse' + course.id,"aria-controls":'collapse' + course.id},on:{"click":_vm.changeTab}},[_vm._v(" "+_vm._s(course.title)+" ")])]),_c('div',{staticClass:"accordion-collapse collapse",attrs:{"id":'collapse' + course.id,"data-bs-parent":"#product-content-accordian"}},[_c('div',{staticClass:"accordion-body"},[(_vm.tabContent && _vm.activeTab == course.id)?_c('ProductContentAccordianList',{attrs:{"tabData":_vm.tabData,"viewMode":_vm.viewMode,"purchased":_vm.purchased,"productVariantId":_vm.productVariantData.id}}):_vm._e()],1)])])}),0):_vm._e(),(!_vm.filteredProductVariantItems.length)?_c('div',{staticClass:"no-content p-3"},[_vm._v(" No content found ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }