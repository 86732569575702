var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-banner",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[(!_vm.isMobile)?_c('div',{staticClass:"d-flex align-items-center gap-3"},[(_vm.user.profilePicture)?_c('div',{staticClass:"user-avatar large"},[_c('img',{attrs:{"src":_vm.user.profilePicture}})]):_c('img',{attrs:{"height":"54","src":require("../Web Assets/user-avatar.svg")}}),_c('p',{staticClass:"mb-0 banner-text"},[_vm._v(" Welcome  -  "+_vm._s(_vm.currentUserName)+" ")])]):_vm._e(),(_vm.systemParameters.allowStudentCourseRegistration)?_c('router-link',{staticClass:"add-btn ms-auto",attrs:{"to":"/register","id":"registerCoursesBtn","role":"button"}},[_c('img',{staticClass:"add-btn-icon",attrs:{"src":require("@/assets/images/add-border-icon.svg")}}),_vm._v(" register new courses ")]):_vm._e()],1),_c('div',[_c('ul',{class:[
					'inline-list',
					{
						'mobile-banner-tabs': _vm.isMobile,
						'banner-tabs': !_vm.isMobile,
					} ]},_vm._l((_vm.tabsList),function(tab){return _c('li',{key:tab.value,class:{
						selected: _vm.activeTab == tab.value,
						'mobile-tab': _vm.isMobile,
						'banner-tab': !_vm.isMobile,
						'd-none': !tab.showOnMobile && _vm.isMobile,
					},attrs:{"id":tab.value},on:{"click":_vm.changeTab}},[_c('i',{class:((tab.iconClass) + " tab-icon")}),_vm._v(_vm._s(!_vm.isMobile ? tab.label : tab.mobileLabel)+" ")])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }