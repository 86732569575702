var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-item-container"},[_c('div',{staticClass:"product-listing-item",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"thumbnail"},[_c('a',[_c('img',{class:("thumbnail-img " + (_vm.isThumbnailError ? 'error' : '')),attrs:{"src":_vm.thumbnailPath,"alt":_vm.data.product.friendlyTitle},on:{"error":function () {
							_vm.isThumbnailError = true;
						}}})])]),(_vm.data.status == _vm.ProductVariantStatus.COMING_SOON)?_c('div',{staticClass:"coming-soon-badge"},[_vm._v(" Coming Soon ")]):_vm._e(),_c('div',{staticClass:"product-content"},[_c('div',{staticClass:"item-product-title",attrs:{"id":'sb-selected-batch-'+_vm.data.id}},[_c('h1',[_vm._v(" "+_vm._s(_vm.data.product.title)+" - "+_vm._s(_vm.data.title)+" "),_c('br')]),_c('b-tooltip',{attrs:{"target":'sb-selected-batch-'+_vm.data.id,"triggers":"hover"}},[_c('b',[_vm._v(_vm._s(_vm.data.product.title))]),_c('br'),_vm._v(" "+_vm._s(_vm.data.title)+" ")])],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"product-variant-desc",attrs:{"title":_vm.data.shortDescription}},[_vm._v(_vm._s(_vm.data.shortDescription ? _vm.data.shortDescription : ""))]),(!_vm.isAdmin && _vm.productTrainersList.length > 0)?_c('h2',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"creator-details",attrs:{"title":_vm.productTrainersList
						.map(function (p) {
							return p.firstName + ' ' + p.lastName;
						})
						.toString()
						.split(',')
						.join(',\n')}},[_c('div',{staticClass:"tutor-images"},[_vm._l((_vm.productTrainersList.slice(0, 2)),function(t){return _c('div',{key:t.id,staticClass:"tutor-image"},[(t.profilePicture)?_c('img',{staticClass:"ls-is-cached lazyloaded",attrs:{"data-src":"../../assets/images/user_img_placeholder.jpg","alt":"","src":t.profilePicture}}):_c('div',{staticClass:"user-avatar"},[_c('span',[_vm._v(_vm._s(t.firstName[0]))])])])}),(_vm.productTrainersList.length > 2)?_c('div',{staticClass:"tutor-image"},[_c('div',{staticClass:"user-avatar extra"},[_vm._v(" "+_vm._s(("+" + (_vm.productTrainersList.length - 2)))+" ")])]):_vm._e()],2),_c('span',[_vm._v(_vm._s(_vm.productTrainersList[0].firstName)+" "+_vm._s(_vm.productTrainersList[0].lastName)+" "),(_vm.productTrainersList.length > 1)?_c('span',[_vm._v(_vm._s(("+" + (_vm.productTrainersList.length - 1) + " trainer" + (_vm.productTrainersList.length > 2 ? "s" : "")))+" ")]):_vm._e()])]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"ratings"},[_c('div',{staticClass:"ratings-left"},[(_vm.data.ratings.value)?_c('div',{staticClass:"rating-container"},[_c('b-form-rating',{attrs:{"value":_vm.data.ratings.value,"readonly":""}})],1):_c('div',[_vm._v(" ")])]),_c('div',{staticClass:"ratings-right"},[_c('h3',[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.data.ratings.value ? parseInt(_vm.data.ratings.value).toFixed(1) : " "))])])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }