import { render, staticRenderFns } from "./CompanyInfoSettings.vue?vue&type=template&id=1af53078&scoped=true&"
import script from "./CompanyInfoSettings.vue?vue&type=script&lang=js&"
export * from "./CompanyInfoSettings.vue?vue&type=script&lang=js&"
import style0 from "./CompanyInfoSettings.vue?vue&type=style&index=0&id=1af53078&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af53078",
  null
  
)

export default component.exports