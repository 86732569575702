import { render, staticRenderFns } from "./HomePageWhyUs.vue?vue&type=template&id=6a4e881c&scoped=true&"
import script from "./HomePageWhyUs.vue?vue&type=script&lang=js&"
export * from "./HomePageWhyUs.vue?vue&type=script&lang=js&"
import style0 from "./HomePageWhyUs.vue?vue&type=style&index=0&id=6a4e881c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4e881c",
  null
  
)

export default component.exports