import { render, staticRenderFns } from "./UserProducts.vue?vue&type=template&id=661effbc&scoped=true&"
import script from "./UserProducts.vue?vue&type=script&lang=js&"
export * from "./UserProducts.vue?vue&type=script&lang=js&"
import style0 from "./UserProducts.vue?vue&type=style&index=0&id=661effbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661effbc",
  null
  
)

export default component.exports