var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$route.meta.isHideAdminLayout!=true)?_c('div',{staticClass:"admin-header"},[_c('router-link',{class:['sidebar-header', { collapsed: _vm.collapsed }],attrs:{"to":"/management/course"}},[_c('img',{class:("sidebar-header-logo" + (_vm.systemParameters.tenantName ? ' w-name' : '')),attrs:{"src":_vm.logoPath}}),(!_vm.collapsed)?_c('span',{staticClass:"ms-3"},[_vm._v(" "+_vm._s(_vm.systemParameters.tenantName ? _vm.systemParameters.tenantName : "")+" ")]):_vm._e()]),_c('div',{staticClass:"header-container"},[(this.$route.meta.isAdmin)?_c('a',{staticClass:"my-nav-link",attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('toggleSidebar')}}},[_c('img',{staticClass:"menu-icon",attrs:{"src":require("@/assets/images/menu-icon-admin-header.png")}})]):_vm._e(),(_vm.showTabs)?_c('div',{staticClass:"header-tabs"},[_c('span',{staticClass:"product-variant-title"},[_vm._v(_vm._s(_vm.productVariantTitle))]),_c('ul',{staticClass:"inline-list"},[_c('li',{staticClass:"header-tab"},[_c('router-link',{attrs:{"to":("/management/course/" + _vm.productVariantId + "/content")}},[_vm._v("Course Content")])],1),(
						this.externalServicesList &&
						this.externalServicesList[_vm.ContentType.EXAM] &&
						this.externalServicesList[_vm.ContentType.EXAM].find(function (x){ return x.serviceProvider.toLowerCase()=='es'; })
					)?_c('li',{staticClass:"header-tab"},[_c('router-link',{attrs:{"to":("/management/course/" + _vm.productVariantId + "/questionbank")}},[_vm._v("Question Bank")])],1):_vm._e(),_c('li',{staticClass:"header-tab"},[_c('router-link',{attrs:{"to":("/management/course/" + _vm.productVariantId + "/feedback")}},[_vm._v("Feedback")])],1),((_vm.user.role || _vm.user.type).toLowerCase() == 'management')?_c('li',{staticClass:"header-tab"},[_c('router-link',{attrs:{"to":("/management/course/" + _vm.productVariantId + "/enrollment")}},[_vm._v("Enrollment")])],1):_vm._e(),((_vm.user.role || _vm.user.type).toLowerCase() == 'management')?_c('li',{staticClass:"header-tab"},[_c('router-link',{attrs:{"to":("/management/course/" + _vm.productVariantId + "/pricing")}},[_vm._v(" Pricing")])],1):_vm._e()])]):_c('div',{staticClass:"searchbar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"search","autocomplete":"off","placeholder":"Find your course here..."},domProps:{"value":(_vm.searchText)},on:{"focus":function($event){_vm.showDropdown = true},"blur":_vm.closeDropdown,"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_vm._m(0),(_vm.showDropdown)?_c('div',{staticClass:"search-dropdown"},[(_vm.searchList.length > 0)?_c('ul',_vm._l((_vm.searchList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.handleLinkClick(item)}}},[_c('img',{staticClass:"searchbar-image",attrs:{"src":require("@/assets/images/sample-thumbnail.jpeg"),"alt":""}}),_vm._v(" "+_vm._s(item.product && item.product.title)+" - "+_vm._s(item.title)+" "),_c('br')])}),0):_vm._e(),(_vm.searchList.length == 0 && _vm.searchText.length > 0)?_c('div',{staticClass:"no-content p-4 text-center text-muted"},[_vm._v(" Sorry! No relevant results found. ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"right-nav"},[(
					_vm.loggedIn &&
					(_vm.systemParameters.AdminKnowledgeBase ||
						_vm.systemParameters.TrainerKnowledgeBase)
				)?_c('button',{staticClass:"my-nav-link desktop-only",on:{"click":_vm.showPDF}},[_vm._v(" Knowledgebase ")]):_vm._e(),(_vm.isNonAdminPage)?_c('router-link',{staticClass:"my-nav-link desktop-only",attrs:{"to":"/management/course"}},[_c('i',{staticClass:"fa fa-arrow-left pe-2"}),_vm._v(" Back to Admin Panel ")]):_vm._e(),_c('div',{staticClass:"user-profile-btn my-nav-link dropdown-toggle",attrs:{"role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('div',{staticClass:"user-avatar"},[(_vm.user.profilePicture)?_c('img',{attrs:{"src":_vm.user.profilePicture}}):_c('span',[_vm._v(_vm._s(_vm.user.firstName[0]))])]),_c('span',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])]),_c('div',{staticClass:"dropdown-menu custom-user-dropdown",attrs:{"aria-labelledby":"coursesMenu"}},[_c('a',{staticClass:"custom-dropdown-item",attrs:{"role":"button"},on:{"click":function($event){return _vm.togglePasswordModal(true)}}},[_vm._v(" Change Password ")]),_c('a',{staticClass:"custom-dropdown-item",attrs:{"role":"button"},on:{"click":_vm.logout}},[_vm._v(" Logout ")])]),_c('a',{staticClass:"my-nav-link",attrs:{"role":"button"},on:{"click":_vm.logout}},[_c('img',{staticClass:"header-link-icon",attrs:{"src":require("@/assets/images/logout-icon.svg")}})])],1)]),_c('ChangePasswordModal',{attrs:{"showModal":_vm.showChangePasswordModal,"profileData":_vm.user},on:{"updateModalState":_vm.updateModalPasswordState}})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"role":"button"}},[_c('img',{staticClass:"search-icon",attrs:{"src":require("@/assets/images/search-icon.svg")}})])}]

export { render, staticRenderFns }