import { render, staticRenderFns } from "./ProductContentSidebar.vue?vue&type=template&id=1d3879e8&scoped=true&"
import script from "./ProductContentSidebar.vue?vue&type=script&lang=js&"
export * from "./ProductContentSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d3879e8",
  null
  
)

export default component.exports