var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayMode === 'carousel' && _vm.productListData.length > 0)?_c('div',{staticClass:"position-relative"},[_c('VueSlickCarousel',_vm._b({ref:"product_carousel",staticClass:"product-listing-container container-fluid"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.productListData),function(productVariant){return _c('ProductListItem',{key:productVariant.id,attrs:{"data":productVariant,"purchased":false}})}),1),_c('a',{staticClass:"carousel-arrow",attrs:{"role":"button"},on:{"click":_vm.prevSlide}},[_c('i',{staticClass:"fas fa-angle-left"})]),_c('a',{staticClass:"carousel-arrow right",attrs:{"role":"button"},on:{"click":_vm.nextSlide}},[_c('i',{staticClass:"fas fa-angle-right"})])],1):_vm._e(),(_vm.showFilters)?_c('ProductFilters',{attrs:{"showFilters":_vm.showFilters,"filters":_vm.filters}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center gap-3 width-100"},[_c('b-spinner'),_vm._v(" "),_c('span',{staticClass:"font-500"},[_vm._v("loading...")])],1):(
					_vm.displayMode !== 'carousel' &&
					_vm.productListData &&
					_vm.productListData.length > 0
				)?_c('div',{class:[
					'product-listing-container',
					{ grid: _vm.displayMode === 'grid', 'list-view': _vm.displayMode === 'list' } ]},_vm._l((_vm.productListData),function(productVariant){return _c('ProductListItem',{key:productVariant.id,class:[
						{
							grid: _vm.displayMode === 'grid',
							'list-view': _vm.displayMode === 'list',
						} ],attrs:{"data":productVariant,"purchased":false}})}),1):(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center gap-3 mt-5 width-100"},[_c('b-spinner'),_vm._v(" "),_c('span',{staticClass:"font-500"},[_vm._v("loading...")])],1):_c('div',{staticClass:"product-listing-container"},[_c('div',{staticClass:"no-data"},[_vm._v("No courses found")])])]):(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center gap-3 width-100"},[_c('b-spinner'),_vm._v(" "),_c('span',{staticClass:"font-500"},[_vm._v("loading...")])],1):_vm._e(),(_vm.productListData.length == 0 && !_vm.showFilters && !_vm.loading)?_c('div',{staticClass:"product-listing-container-data"},[_vm._v(" No courses found ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }