import { render, staticRenderFns } from "./ProductContentChaptersConfidence.vue?vue&type=template&id=609a70b7&scoped=true&"
import script from "./ProductContentChaptersConfidence.vue?vue&type=script&lang=js&"
export * from "./ProductContentChaptersConfidence.vue?vue&type=script&lang=js&"
import style0 from "./ProductContentChaptersConfidence.vue?vue&type=style&index=0&id=609a70b7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609a70b7",
  null
  
)

export default component.exports