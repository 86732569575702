var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[(this.$route.meta.isHideAdminLayout!=true)?_c('div',{class:['custom-sidebar', { collapsed: _vm.collapsed }],attrs:{"id":"admin-sidebar"}},[_c('ul',{staticClass:"sidebar-list"},[_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'CourseManagement' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/course","role":"button"}},[_c('object',{staticClass:"sidebar-item-icon color-change-svg",attrs:{"data":require('@/assets/images/course-management-icon.svg'),"type":"image/svg+xml"}}),(!_vm.collapsed)?_c('span',[_vm._v("Course Management")]):_vm._e()])],1),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'UsersManagement' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/users","role":"button"}},[_c('object',{staticClass:"sidebar-item-icon color-change-svg",attrs:{"data":require('@/assets/images/admission-processing-icon.svg'),"type":"image/svg+xml"}}),(!_vm.collapsed)?_c('span',[_vm._v("Users Management")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'InvoiceManagement' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/invoices","role":"button"}},[_c('i',{staticClass:"fal fa-receipt sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("Invoices")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'PaymentManagement' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/payments","role":"button"}},[_c('i',{staticClass:"fal fa-cash-register sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("Payments")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'SystemPreferences' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/preferences","role":"button"}},[_c('i',{staticClass:"fal fa-cogs sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("System Preferences")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'AttendanceWithBarcode' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/barcode-attendance","role":"button"}},[_c('i',{staticClass:"fal fa-qrcode sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("Attendance With QR Code")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'BulkOperations' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/bulk-operations","role":"button"}},[_c('i',{staticClass:"fal fa-users-class sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("Bulk Operations")]):_vm._e()])],1):_vm._e(),(!_vm.isTrainer)?_c('li',{class:[
					'sidebar-list-item',
					{ active: _vm.activeTab == 'Support' } ]},[_c('router-link',{staticClass:"link",attrs:{"to":"/management/Support","role":"button"}},[_c('i',{staticClass:"fal fa-user-headset sidebar-item-icon primary"}),(!_vm.collapsed)?_c('span',[_vm._v("Support Tickets")]):_vm._e()])],1):_vm._e()])]):_vm._e(),_c('div',{class:['page-content', { collapsed: _vm.collapsed }]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }