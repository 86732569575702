import { render, staticRenderFns } from "./BannerWithTabs.vue?vue&type=template&id=fe4cef12&scoped=true&"
import script from "./BannerWithTabs.vue?vue&type=script&lang=js&"
export * from "./BannerWithTabs.vue?vue&type=script&lang=js&"
import style0 from "./BannerWithTabs.vue?vue&type=style&index=0&id=fe4cef12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4cef12",
  null
  
)

export default component.exports