import { render, staticRenderFns } from "./ColorThemeSettings.vue?vue&type=template&id=71e6e967&scoped=true&"
import script from "./ColorThemeSettings.vue?vue&type=script&lang=js&"
export * from "./ColorThemeSettings.vue?vue&type=script&lang=js&"
import style0 from "./ColorThemeSettings.vue?vue&type=style&index=0&id=71e6e967&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e6e967",
  null
  
)

export default component.exports