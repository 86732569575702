var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-banner",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[(!_vm.isMobile)?_c('p',{staticClass:"mb-0"},[_vm._v("Welcome "+_vm._s(_vm.currentUserName))]):_vm._e(),(_vm.systemParameters.allowStudentCourseRegistration)?_c('router-link',{staticClass:"add-btn ms-auto",attrs:{"to":"/register","id":"registerCoursesBtn","role":"button"}},[_c('img',{staticClass:"add-btn-icon",attrs:{"src":require("@/assets/images/add-border-icon.svg")}}),_vm._v(" register new courses ")]):_vm._e()],1),_c('div',[_c('ul',{class:[
					'inline-list',
					{
						'mobile-banner-tabs': _vm.isMobile,
						'banner-tabs': !_vm.isMobile,
					} ]},[_c('li',{class:{
						selected: this.activeTab == 'courses',
						'mobile-tab': _vm.isMobile,
						'banner-tab': !_vm.isMobile,
					},attrs:{"id":"courses"},on:{"click":_vm.changeTab}},[_c('i',{staticClass:"fas fa-book tab-icon"}),_vm._v(_vm._s(_vm.tabText[0])+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }