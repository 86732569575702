var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 page-content-container fix-height"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"page-title"},[_c('h3',[_vm._v(_vm._s(_vm.isManagementUser ? "" : "My ")+"Support Tickets")]),(!_vm.isManagementUser)?_c('p',[_vm._v(" Create and track support requests as needed. You can view and access requests ")]):_vm._e()]),(!_vm.isManagementUser)?_c('div',{staticStyle:{"padding-top":"18px"}},[_c('button',{staticClass:"add-btn",attrs:{"role":"button"},on:{"click":function($event){return _vm.showAddTicketModal()}}},[_c('img',{staticClass:"add-btn-icon",attrs:{"src":require("@/assets/images/add-border-icon.svg")}}),_vm._v(" Add New Ticket ")])]):_vm._e(),(_vm.isManagementUser)?_c('div',[_c('button',{staticClass:"add-btn",attrs:{"role":"button"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v(" Refresh ")])]):_vm._e()])]),_c('div',{staticClass:"container d-flex"},[_c('div',{staticClass:"d-flex mb-4 gap-3"},[_c('button',{class:[
          'filter-user-type-btn',
          { active: _vm.filter.serviceID == null } ],on:{"click":function($event){return _vm.setServiceID(null)}}},[_vm._v(" All ")]),_vm._l((_vm.ticketServices),function(service){return _c('button',{class:[
          'filter-user-type-btn',
          { active: _vm.filter.serviceID == service.id } ],on:{"click":function($event){return _vm.setServiceID(service.id)}}},[_vm._v(" "+_vm._s(service.title)+" ")])})],2),_c('div',{staticClass:"d-flex mb-4 gap-3 ms-auto"},[_c('button',{class:['filter-user-type-btn', { active: _vm.filter.status == null }],on:{"click":function($event){return _vm.setStatus(null)}}},[_vm._v(" All ")]),_c('button',{class:[
          'filter-user-type-btn',
          { active: _vm.filter.status == 'opened' } ],on:{"click":function($event){return _vm.setStatus('opened')}}},[_vm._v(" Opened ")]),_c('button',{class:[
          'filter-user-type-btn',
          { active: _vm.filter.status == 'closed' } ],on:{"click":function($event){return _vm.setStatus('closed')}}},[_vm._v(" Closed ")])])]),_c('div',{staticClass:"table-container container"},[_c('table',{staticClass:"table table-hover table-borderless"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"2%","text-align":"center"}},[_vm._v("#")]),_c('th',{staticStyle:{"width":"8%"}},[_vm._v("Ticket #")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Service")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Subject")]),(_vm.isManagementUser)?_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Opened By")]):_vm._e(),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Last person to intervene")]),(!_vm.isManagementUser)?_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Creation date")]):_vm._e(),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.tickets),function(ticket,index){return (!_vm.isTicketLoader)?_c('tr',{key:index,attrs:{"id":"ticket-list","title":"Click to View Ticket"},on:{"click":function($event){return _vm.showViewTicketModal(ticket.id)}}},[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(index+1))]),_c('td',[_vm._v(_vm._s(_vm.parseTicketHash(ticket.id)))]),_c('td',[_vm._v(_vm._s(ticket.service.title))]),_c('td',[_vm._v(_vm._s(ticket.subject))]),(_vm.isManagementUser)?_c('td',[_vm._v(" "+_vm._s(ticket.user.firstName)+" "+_vm._s(ticket.user.lastName)),_c('br'),_c('small',[_vm._v(_vm._s(_vm.formatDate(ticket.createdAt)))])]):_vm._e(),_c('td',[(ticket.lastTicketReply && ticket.lastTicketReply.user)?_c('span',[_vm._v(" "+_vm._s(ticket.lastTicketReply.user.firstName + " " + ticket.lastTicketReply.user.lastName)+" "),(ticket.lastTicketReply)?_c('small',[_c('br'),_vm._v(" "+_vm._s(_vm.formatDate(ticket.lastTicketReply.createdAt))+" ")]):_vm._e()]):_vm._e()]),(!_vm.isManagementUser)?_c('td',[_vm._v(_vm._s(_vm.formatDate(ticket.createdAt)))]):_vm._e(),_c('td',[_c('div',{class:[
              'status-tag',
              {
                warning: ticket.status && ticket.status.toLowerCase() == "opened",
                success: ticket.status && ticket.status.toLowerCase() == "closed",
              } ]},[_vm._v(" "+_vm._s(ticket.status)+" ")])])]):_vm._e()}),0)]),(_vm.isTicketLoader)?_c('div',{staticClass:"d-flex align-items-center justify-content-center gap-3 p-3 font-500 m-5"},[_c('b-spinner'),_vm._v(" Loading... ")],1):(!_vm.isTicketLoader && (!_vm.tickets || _vm.tickets.length <= 0))?_c('h5',{staticClass:"text-center p-4 m-0"},[_vm._v(" No Tickets found ")]):_vm._e()]),_c('AddTicketModal',{attrs:{"showModal":_vm.showNewTicket},on:{"updateModalState":_vm.toggleAddTicket}}),_c('ViewTicketModal',{attrs:{"showModal":_vm.showViewTicket && _vm.selectedTicketID != null,"ticketID":_vm.selectedTicketID},on:{"updateModalState":_vm.toggleViewTicket}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }