import { render, staticRenderFns } from "./ProductContentChaptersContentListItem.vue?vue&type=template&id=0f5955da&scoped=true&"
import script from "./ProductContentChaptersContentListItem.vue?vue&type=script&lang=js&"
export * from "./ProductContentChaptersContentListItem.vue?vue&type=script&lang=js&"
import style0 from "./ProductContentChaptersContentListItem.vue?vue&type=style&index=0&id=0f5955da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5955da",
  null
  
)

export default component.exports