import { render, staticRenderFns } from "./RecordedVideo.vue?vue&type=template&id=47ceaaad&scoped=true&"
import script from "./RecordedVideo.vue?vue&type=script&lang=js&"
export * from "./RecordedVideo.vue?vue&type=script&lang=js&"
import style0 from "./RecordedVideo.vue?vue&type=style&index=0&id=47ceaaad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ceaaad",
  null
  
)

export default component.exports